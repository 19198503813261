import React, { useState, useRef, useEffect } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import ArrowRightICon, {
  FeatureArrowWithColor,
} from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { ComingSoonWrapper } from "../../components/common/PricingCard";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { DownArrowCircle } from "../../react/Icons";
import { useMedia } from "use-media";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import { useSwipeable, Swipeable } from "react-swipeable";
import { RequestForm } from "../../components/form";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

export const icon1 = require("../..//assets/img/feature/sec_1_bg.png");
export const cardOne = require("../..//assets/images/homepage/group1.png");
export const cardTwo = require("../..//assets/images/homepage/group4.png");
const teams = require("../../assets/img/feature/chat_channel/teams_header.png");

const section1 = require("../../assets/img/feature/chat_channel/chat_channel_sec_1.png");

const slack = require("../../assets/img/feature/chat_channel/slack_header.png");
const widget = require("../../assets/img/feature/chatbot_widget/widget_header.png");
const topImage = require("../../assets/img/feature/ominichannel/omni_header.png");

const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

export const autoDesigner = require("../../assets/img/feature/updated/automation.png");
export const chatChannel = require("../../assets/img/feature/updated/chat-channel.png");
export const logs = require("../../assets/img/feature/updated/logs.png");
export const marketplace = require("../../assets/img/feature/updated/marketplace.png");
export const dialog = require("../../assets/img/feature/updated/dialog.png");
export const onprem_icon = require("../../assets/img/feature/updated/on_prem.png");
export const conversationAI = require("../../assets/img/feature/updated/c_ai.png");
export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");

export const otherFeatures = [
  {
    image: dialog,
    alt: "Chatbot Builder​​",
    header: "Chatbot Builder​",
    content:
      "Use no-code visual canvas to create engaging conversations for your chatbot easily in minutes.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: conversationAI,
    alt: "Conversational AI Platform",
    header: "Conversational AI Platform",
    content:
      "Learn more on workativ assistants’ conversational ai platform capabilities.",
    link: "/conversational-ai-platform/conversational-ai",
  },
  {
    image: autoDesigner,
    alt: "Chatbot Automation​",
    header: "Chatbot Automation​",
    content:
      "Add IT or HR workflow automations to chatbot through app integration and pre-built app workflows in few clicks.",
    link: "/conversational-ai-platform/chatbot-automation",
  },

  {
    image: knowledgeAi,
    alt: "Knowledge AI",
    header: "Knowledge AI​",
    new: true,
    content:
      "Knowledge AI leverages the power of LLMs and Generative AI to provide contextually relevant responses to user queries.",
    link: "/conversational-ai-platform/knowledge-ai",
  },

  {
    image: chabotApproval,
    alt: "Chatbot Approval",
    header: "Chatbot Approval​",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },
  {
    image: marketplace,
    alt: "Chatbot Marketplace",
    header: "Chatbot Marketplace",
    content:
      "Download pre-built chatbots, 100+ app integrations, and 1000+ app workflows in seconds and go live instantly.",
    link: "/conversational-ai-platform/app-workflow-template-marketplace",
  },
  {
    image: liveAgent,
    alt: "Live Agent Handover",
    header: "Live Agent Handover",
    content:
      "Seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history.",
    link: "/conversational-ai-platform/agent-handover-chatbot",
  },
  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: logs,
    alt: "Chatbot Analytics & Performance",
    header: "Chatbot Analytics & Performance",
    content:
      "Gather insight to craft better end user experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/chatbot-analytics-performance",
  },
];

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

export default function Analytics() {
  const shirts = { teams, slack, widget };

  const slider = useRef(null);
  const [selected, setSelected] = useState(shirts.teams);

  const [menuActive, setMenuState] = useState(false);

  const [activeSlider, setActiveSlider] = useState(0);

  const isMobile = useMedia({ maxWidth: "520px" });

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log("swiped left");
      slider.current.slideNext();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      console.log("swiped right");
      slider.current.slidePrev();
    },
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const _slidePrev = () => {
    console.log("slider.current", slider.current);
    slider.current.slidePrev();
  };

  const _slideNext = () => {
    slider.current.slideNext();
  };

  const _goToSlide = (slideNo) => {
    slider.current.goToSlide(slideNo - 1);
  };

  const onSelect = (item) => {
    setActiveSlider(item);
    // console.log(item, "onSelect");
  };

  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Chatbot Channels - Integrate Chatbot with Teams and Slack | Workativ Assistant Chatbot"
        description="Use workativ assistant to integrate and deploy chatbot on Slack and Microsoft Teams in few clicks. Also, integrate slack and teams with apps and create workflows for slack and teams easily without any coding."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={topImage}
        ogTitle="Chatbot Channels - Integrate Chatbot with Teams and Slack | Workativ Assistant Chatbot"
        ogDescription="Use workativ assistant to integrate and deploy chatbot on Slack and Microsoft Teams in few clicks. Also, integrate slack and teams with apps and create workflows for slack and teams easily without any coding."
      />
      <Container additionalClass={"feature-display-none"}>
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <section className="main-slider feature_inner_page  bg_feature_slider bg_feature_slider_wrapper trial_page_img mb-0 features_indvidual_dec">
            <div className="feature_bg_img"></div>
            <div className="container">
              <div className="row">
                <div className="back_features">
                  <div className="back_features_page">
                    <a
                      href="/conversational-ai-platform/features"
                      className="url_manipulation font-section-normal-text-medium"
                    >
                      <span>←</span>Back to Features
                    </a>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="main-slider-left">
                    <h1 className="font-page-header">
                      Chatbot Channel Integration
                    </h1>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      Deploy your chatbot on Teams, Slack or as Chat Widget in
                      few clicks, connect your bot with apps to automate
                      workflows, and supercharge your workplace support
                      experience.
                    </p>
                    <RequestForm isFooterForm={false} />
                    {/* {isSmall ? null : <OnScrollPopup />} */}
                  </div>
                </div>
                <div
                  className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header chat_img_header ${
                    isMobile ? "" : "pl-0"
                  }`}
                >
                  <div className="features_change_img desktop_slider_cc">
                    <div
                      className="chat-channel assistant_change_img feature_cc_slider mb-0 m-0 width-100"
                      {...handlers}
                    >
                      <ReactBootstrapCarousel
                        animation={true}
                        autoplay={true}
                        slideshowSpeed={7000}
                        defaultActiveIndex={0}
                        indicators={false}
                        onSelect={onSelect}
                        ref={slider}
                        version={4}
                      >
                        <div className="corousel-image-center">
                          <img
                            className="d-block mb-0 padding-0-imp shadow-none width-100"
                            src={teams}
                            alt="chatbot channel integration"
                          />
                        </div>
                        <div className="corousel-image-center">
                          <img
                            className="d-block  mb-0 padding-0-imp shadow-none width-100"
                            src={slack}
                            alt="chatbot channel integration"
                          />
                        </div>
                        <div className="corousel-image-center">
                          <img
                            className="d-block  mb-0 padding-0-imp shadow-none width-100"
                            src={widget}
                            alt="chatbot channel integration"
                          />
                        </div>
                      </ReactBootstrapCarousel>
                      <div className="row padding-top-bottom-20">
                        <div className="swipe-chat-channel mt-3 ">
                          <div
                            className={
                              activeSlider !== 0
                                ? `swipe-one active`
                                : "swipe-one"
                            }
                            tabindex="1"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              if (activeSlider !== 0) {
                                _slidePrev();
                              }
                            }}
                          >
                            <FeatureArrowWithColor
                              fillColor={
                                activeSlider !== 0 ? "#2468F6" : "#B7B7B7"
                              }
                              className="swipe-icon ar-left rotate-180"
                            />
                          </div>
                          <div
                            className={
                              activeSlider === 0
                                ? `swipe-one active`
                                : "swipe-one"
                            }
                            onClick={(e) => {
                              _goToSlide(1);
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <span></span>
                          </div>
                          <div
                            className={
                              activeSlider === 1
                                ? `swipe-one active`
                                : "swipe-one"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              _goToSlide(2);
                            }}
                          >
                            <span></span>
                          </div>
                          <div
                            className={
                              activeSlider === 2
                                ? `swipe-one active`
                                : "swipe-one"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              _goToSlide(3);
                            }}
                          >
                            <span></span>
                          </div>
                          <div
                            className={
                              activeSlider !== 2
                                ? `swipe-one active`
                                : "swipe-one"
                            }
                            tabindex="2"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              if (activeSlider !== 2) {
                                _slideNext();
                              }
                            }}
                          >
                            <FeatureArrowWithColor
                              fillColor={
                                activeSlider !== 2 ? "#2468F6" : "#B7B7B7"
                              }
                              className="swipe-icon ar-right"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="mobile-section"
            className="features_content_left features_content_left_cc pl-20-tage feature_pageinner_content_img m_b_80 section-padding-10 background-white margin-top-5-negative  omnichannel-mobile-section"
          >
            <div className="feature_bg_img"></div>
            <div className="container">
              <h3 className="font-section-sub-header text-align-center mb-4">
                Deploy chatbot on multiple channels
              </h3>
              <div className="row">
                <div className="col-md-12 col-lg-4 col-sm-12 col-12 feature_page_content_left order_2-res mob_order_none">
                  <div
                    className={`omni_channel_int_box min-height-150 ${
                      isMobile ? "mb-3" : null
                    }`}
                  >
                    <img src={FeatureTeams} className="chat_omnichannel_icon" />
                    <h3 className="font-size-resposive font-section-sub-header-small text-black">
                      Microsoft Teams chatbot
                    </h3>
                    <p className="omni-min-text-height mb-0 font-section-normal-text-testimonials line-height-18">
                      Deploy chatbot on MS Teams in few clicks. And connect your
                      teams chatbot with multiple apps to automate workflows via
                      workativ assistant.
                    </p>
                    <div class="card_link_landing">
                      <a
                        class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                        href="/conversational-ai-platform/ms-teams-bot"
                      >
                        More on teams chatbot&nbsp; &#8594;
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 col-sm-12 col-12 feature_page_content_left mob_order_none">
                  <div
                    className={`omni_channel_int_box min-height-150 ${
                      isMobile ? "mb-3" : null
                    }`}
                  >
                    <img src={FeatureSlack} className="chat_omnichannel_icon" />
                    <h3 className="font-size-resposive font-section-sub-header-small text-black">
                      Slack chatbot
                    </h3>
                    <p className="omni-min-text-height mb-0 font-section-normal-text-testimonials line-height-18">
                      Deploy chatbot on slack in few clicks. And connect your
                      slack chatbot with multiple apps to automate workflows via
                      workativ assistant.
                    </p>
                    <div class="card_link_landing">
                      <a
                        class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                        href="/conversational-ai-platform/slack-chatbot"
                      >
                        More on slack chatbot&nbsp; &#8594;
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 col-sm-12 col-12 feature_page_content_left mob_order_none">
                  <div className="omni_channel_int_box min-height-150">
                    <img src={FeatureChat} className="chat_omnichannel_icon" />
                    <h3 className="font-size-resposive font-section-sub-header-small text-black">
                      Chatbot Widget
                    </h3>
                    <p className="omni-min-text-height mb-0 font-section-normal-text-testimonials line-height-18">
                      Deploy chatbot as a standalone chat widget on your website
                      or self-service portal in few clicks. And connect your
                      chatbot with multiple apps to automate workflows easily.
                    </p>

                    <div class="card_link_landing">
                      <a
                        class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                        href="/conversational-ai-platform/chatbot-widget"
                      >
                        More on chatbot widget&nbsp; &#8594;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ChannelSec />

          <section className="cards_features">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header pl-0 pr-0">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2 className="font-section-header">
                        Explore More Features
                      </h2>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        Our 3-in-1 support automation platform combines the
                        power of Gen AI chatbot for automating chats, App
                        workflow automation for auto-resolution of issues, and
                        Shared Live Inbox for seamless live chat interactions.
                        And more…!
                      </p>
                    </div>
                  </div>
                  <section className="whitepaper_cards">
                    <div className="container-fluid p-0 c_a">
                      <div className="row">
                        <ul className="cards">
                          {otherFeatures.map((data) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    loading="lazy"
                                    src={data.image}
                                    alt={data.alt}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title font-section-normal-text-medium">
                                    {data.header}
                                    {data.beta ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        Beta
                                      </span>
                                    ) : null}
                                    {data.new ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        New
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className="card__text font-section-small-text pb-0">
                                    {data.content}
                                  </p>
                                  <div class="card_link_landing">
                                    <a
                                      className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                      href={data.link}
                                    >
                                      Know more&nbsp; &#8594;
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          {/* <GoToPopup />
          <CookiesPoup /> */}
          <div className="isfooter_form">
            <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}

function ChannelSec() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left chat_channel_features_parent">
      <div className="container">
        <div className="feature_ind_chnl_con">
          <div
            className={`feature_ind_chnl_con_left ${
              isSmall ? "width-100" : "width-40"
            } `}
          >
            <h3 className="font-section-normal-text-medium-link">
              Agent handover on Slack and MS Teams
            </h3>
            <p className="font-section-normal-text-testimonials line-height-18 pb-0">
              Workativ’s chatbot can automate and resolve end-user issues &
              service requests, but in some cases, and in the event that chatbot
              is unable to resolve, it can offer to handover the chat to a live
              agent operating from MS Teams or Live Chat Inbox
              <span className="color-important-chnl">*</span>.
            </p>
            <p className="font-section-normal-text-testimonials line-height-18 pb-0">
              Isn’t that wonderful?
            </p>
            <a
              className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
              href="/conversational-ai-platform/agent-handover-chatbot"
            >
              More on Live Agent Handover&nbsp; &#8594;
            </a>
          </div>
          <img
            className={`feature_ind_chnl_con_right align-self-center ${
              isSmall ? "w-100" : "w-60"
            }`}
            src={section1}
          />
        </div>
      </div>
    </section>
  );
}
